import ImageViewModel from "./ImageViewModel.js";

export default (image, width, height) => {
	if (!image) return undefined;

	let image_xs = ImageViewModel(image.image_xs);
	let image_sm = ImageViewModel(image.image_sm);
	let image_md = ImageViewModel({ ...image.image_md, width, height });
	let image_lg = ImageViewModel(image.image_4k);

	return {
		xs: image_xs && image_xs.src,
		sm: image_sm && image_sm.src,
		md: image_md && image_md.src,
		xl: image_lg && image_lg.src,
		alt: image.alt,
	};
};
