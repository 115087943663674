export default function (image) {
	if (!image) return undefined;

	let src = image.src;

	if (!src) {
		const id = image.id && image.id.replace(":", "");

		const extensionRegex = /\.[^/.]+$/;
		const endExtension = extensionRegex.exec(image.filename);
		const filename = image.filename && image.filename.replace(extensionRegex, "");
		src = "/binaries/" + filename + "_" + id + endExtension;
	}

	if (image.height && image.width) {
		if (src.indexOf("?") > -1) src += `&width=${image.width}&height=${image.height}`;
		else src += `?width=${image.width}&height=${image.height}`;
	} else if (image.width) {
		if (src.indexOf("?") > -1) src += `&width=${image.width}`;
		else src += `?width=${image.width}`;
	} else if (image.height) {
		if (src.indexOf("?") > -1) src += `&height=${image.height}`;
		else src += `?height=${image.height}`;
	}

	return {
		src,
		alt: image.alt,
	};
}
